// import { useState, useEffect } from 'react';
// import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
//import '@aws-amplify/ui-react/styles.css';
import './App.css';
// import Error404 from './pages/error404';
// import LoginPage from './pages/login/loginPage';
// import ImgUpload from './pages/imgUpload/imgUpload';
import HomePage from './pages/home/homePage';
import NavBar from './common/components/navbar/navbar';
import ComingSoon from './pages/coming-soon/coming-soon';

import { Routes, Route } from "react-router-dom";
import { FaTruckLoading } from 'react-icons/fa';

function App() {

  // const [user, setUser] = useState(null)

  // useEffect(() => {
  //   checkUser();

    
  //   Amplify.Hub.listen('auth', ({ payload }) => {
  //     if (payload.event === 'signOut') {
  //       setUser(null);
  //     }
  //   });
  // }, []);

  // sync function checkUser() {
  //   try {
  //     const data = await ResourcesConfig.Auth.currentUserPoolUser();
  //     const userInfo = {
  //       username: data.username,
  //       name: data.attributes.name,
  //       email: data.attributes.email
        
  //     };
  //     setUser(userInfo);
      
  //   } catch (err) {
  //     console.log('Error checking user: ', err);
  //     setUser(null);
  //   }
  // }

  // function signOut() {
  //   Auth.signOut().catch((err) => console.log('Error signing out: ', err));
  // }

  return (
    <div className="App">
      {/* <NavBar /> */}
      <Routes>
        <Route path="/" element = {<ComingSoon />} />
        <Route path="/*" element = {<ComingSoon />} />
        {/* 
        <Route path="/" element = {<HomePage />} />
        <Route path="/signup" element = {<LoginPage />} />
        <Route path="/imgUpload" element = {<ImgUpload />} />
        <Route path="/*" element = {<Error404 />} /> */}
      </Routes>
    </div>
  );
}

export default App;
