import './loading.css';

import { Container, Row, Col } from "reactstrap"
const Loading = () => {
    return(
      <Container>
        <Row className="vh-100 align-items-center">
      <Row>
        <Col ><h4>Loading ....</h4></Col>
      </Row>
        </Row>
      </Container>
    )
  }
  
export default Loading