import { useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader} from 'reactstrap';
import './coming-soon.css';
import Loading from './../../common/components/loading/loading'

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const deadline = new Date('March 31, 2024 23:59:59')
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'} as any;
const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
};

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const renderTime = (dimension: string, time: number) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const ComingSoon = () => {

  const [day, setDay] = useState<number | undefined>(undefined)
  const [hour, setHour] = useState<number | undefined>(undefined)
  const [minute, setMinute] = useState<number | undefined>(undefined)
  const [second, setSecond] = useState<number | undefined>(undefined)
  const [remainingTime, setRemainingTime] = useState<number | undefined>(undefined)
  
  setInterval(function() {
    const date = new Date()
    const total = Date.parse(deadline.toString()) - Date.parse(date.toString())
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/60000) % 60 );
    const hours = Math.floor( (total/(3600000)) % 24 );
    const days = Math.floor( total/(86400000) );
    setSecond(seconds)
    setMinute(minutes)
    setHour(hours)
    setDay(days)
    setRemainingTime(total)
  },1000)

  if(day === undefined || hour === undefined || minute === undefined || second === undefined || remainingTime === undefined){
    return (
      <Loading />
    )
  }

  return (
    <Container className="coming-soon">
        <Row className="loader align-items-center">
            <Container className="wrapper align-items-center p-0 m-0 g-0">
              <Row className="align-items-center m-0 g-0 p-0 w-100 h-100">
                <Row className="header p-0 m-0">
                  <Row className="top-header m-0 g-0 w-100 align-items-center">
                    <div className="">We are Coming Soon</div>
                  </Row>
                  <Row className="bottom-header m-0 g-0 w-100 align-items-center">
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4} className="left">Time to Release :</Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8} className="right">{day} days, {hour} hours, {minute} minutes, {second} seconds</Col>
                  </Row>
                </Row>
                <Row className="flip-clock-row align-items-center g-0">
                    <Col className="flip-clock-col align-items-center w-100 p-0 m-0">
                      <FlipClockCountdown to={deadline} className="align-items-center flip-clock"/>
                    </Col>
                </Row>
                <Row className="footer g-0 w-100 h-25">
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5} className="left">Web release on </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7} className="right">{deadline.toLocaleDateString(undefined,options)}</Col>
                </Row>
              </Row>
            </Container>
        </Row>
      </Container>
  )

    
}

export default ComingSoon;
